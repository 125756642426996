
div,h1.h4,p, button{
  color: #fff;
  user-select: none;
}

body{
}

.wrapper {
  position: relative;
  padding: 0 40px ;
  min-height: 100vh;
  overflow-x: hidden;

  @media screen and (max-width: 600px){
    padding: 0 20px;
    min-width:335px;
  }
  @media screen and (max-width: 360px){
    padding: 0 10px;
  }
}

.content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
  min-height: 100vh;
  transition: opacity 0.5s;
}

.hidden{
  opacity: 0;
}


@font-face {
  font-family: 'Redcollar';
  src: local('Redcollar'), local('Red-Сollar'),
  url('./fonts/Redcollar.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Commons';
  src: local('TT Commons'),
  url('./fonts/TT Commons Medium.otf') format('otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}



