.banner{
  color: white;
  z-index: 20;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 1;
  transition: all 0.25s;

  img {z-index: 200;}


  p{
    font-family: Redcollar, sans-serif;
    font-weight: 400;
    font-size:clamp(28px, 8vw, 36px);
    line-height: 96%;
    letter-spacing: -0.04em;
    margin-bottom: 72px;
    text-align: center;
    z-index: 200;
  }

  button{
    position: relative;
    z-index: 200;
    background: none;
    outline: none;
    border: 1px solid #FFFFFF;
    padding: clamp(16px, 12vw,20px)  clamp(40px, 12vw,64px);
    border-radius: 40px;
    cursor: pointer;
    font-family: 'Redcollar', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size:clamp(18px, 5vw,32px);
    line-height: 96%;
    background: rgba(255, 255, 255, 0.192);
    backdrop-filter: blur(5px);
    user-select: none;
    color: white;
    transition: all 0.25s;

    &:hover{
      background: #FF30F7;
      border: 1px solid #FF30F7;;
    }
  }
}

.hidden{
  opacity: 0;
  transition: all 0.25s;

}