
.audio{
  position: absolute;
  top: 48px;
  right: 35px;
  color: #FFFFFF;
  width: 51px;
  height: 12px;
  cursor: pointer;



  &-muted{
    height: 2px;
    background-color: #fff;
    transition: all 0.25s;
    margin-top: 5px;
  }

  &-active{
    width: 51px;
    height: 12px;
    margin-bottom: 5px;

  }

  &:hover &-muted{
    @media  screen and (min-width: 768px) {
      background-color: #FF30F7;
    }
  }
  @media screen and (max-width: 768px){
    width: 40px;
    height: 6px;
    right: 20px;
    //margin-bottom: 2px;
  }
}