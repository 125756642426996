.title {
  position: relative;
  margin-top: clamp(76px, 10vw, 144px);




  &-text {
    font-family: "Redcollar", sans-serif;
    font-weight: 400;
    position: relative;
    font-size: clamp(74px, 11vw, 190px);
    line-height: 96%;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    max-width: 1417px;
    user-select: none;
    margin: 0 0 40px 0;
    z-index: 2;
    overflow: hidden;

    &-word{
      position: relative;
      overflow-y: hidden;
      display: inline-block;
      margin-right:20px ;



    }
    &-symbol{
      display: inline-block;
      position: relative;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          position: relative;
          transition: all $i/10+ 0.45s ease-out;
          transform-style: preserve-3d;
          will-change: transform;
          //animation: wave 1.5s ;
          transform: translateY(90% + $i * 10%);

          @keyframes wave {

            0% {
              transform:translateY(90%);
            }

            50% {
              transform:translateY(-20%);
            }
            100%{
              transform:translateY(0);
            }


          }
        }
      }

    }

    span{
      position: relative;

    }
  }



  &-button {
    position: relative;
    z-index: 20;
    max-width:clamp(200px, 45vw, 300px);
    border: 1px solid #ffffff;
    border-radius: 40px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    user-select: none;
    transition: all 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;

    a{
      display: flex;
      text-decoration: none;
      font-family: "Redcollar", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: clamp(18px, 5vw, 32px);
      line-height: 96%;
      width: 100%;
      height: 100%;
      color: white;
      padding: clamp(16px, 12vw, 20px) clamp(40px, 12vw, 64px);
      border-radius: 40px;
      align-items: center;
      justify-content: center;

    }

    &:hover {
      background: #ff30f7;
      border: 1px solid #ff30f7;
    }
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 180px;
  }
}

.active{

  &-symbol{
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        position: relative;
        transform: translateY(0);
      }
    }
  }

}



