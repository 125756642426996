.footer{
  width: 100%;
  margin: 0 auto 60px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  transform: translateY(100%);
  opacity: 0;


  &-rc{
    position: relative;
    font-family: Redcollar, sans-serif;
    font-size: clamp(17px,3vw ,22px);
    font-weight: 500;
    min-width: clamp(128px, 21vw, 170px);
    text-align: right;
    color: white;
    transition: all, 0.25s;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;


    svg{
      width:clamp(35px,5vw ,44px);
      height: clamp(15px,3vw ,19px);

    }
    svg > path{
      position: absolute;
      left: 0;
      fill: #FF30F7;

    }

    &:hover{
      color: #FF30F7;
      svg > path{
        fill: #fff;
      }
    }

  }

  &-fkn{
    cursor: pointer;
    z-index: 1;

    svg{
      width: clamp(50px, 4vw,63px);
      height: clamp(16px, 3vw,20px);
    }
    svg > path{
      fill: #fff;
      transition: all, 0.25s;
    }
    &:hover{
      svg > path{
        fill: #FF30F7;
      }
    }
  }

  &-line{
    position: relative;
    z-index: 1;
    width: 100%;
    height: 1px;
    background-color:#fff;
    margin: 0  clamp(12px,3vw ,40px);

  }
}

.activeF{
  transform: translateY(0);
  opacity: 1;
  transition: all 0.8s;
}