.bg{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background:
          //linear-gradient(185.45deg, #7305FF 0%, rgba(188, 136, 255, 0.001) 0%),
          radial-gradient(99.17% 99.17% at 97.75% 99.75%, #000000 0%, #210732 270%, #1E0030 00%),
          linear-gradient(199.88deg, #FF05E6 400.49%, rgba(188, 136, 255, 0) 500.03%),
          linear-gradient(117.77deg, #FF05E6 500.44%, rgba(188, 136, 255, 0) 500.29%);

  &-img{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index:0;
    mix-blend-mode: color-dodge;
    width: clamp(500px, 80vw,907px);
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  &-img1{
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    mix-blend-mode: color-dodge;
    @media screen and (max-width: 600px) {
      transform: rotate(38deg);
      //top: 100px;
      right: -40%;
    }
  }
}

.bg{
  position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  &-scene{
    position: absolute;
    width: 100%;
    height:clamp(100%, 100vw, 100%);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
}
