.typography{
  position: absolute;
  right: 8%;
  top: 40%;
  transform: translateY(30%);
  opacity: 0;

  &-title{
    font-family: 'Redcollar', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size:clamp(28px, 8vw, 36px);
    line-height: 96%;
    letter-spacing: -0.04em;
  }

  &-text{
    font-family: 'TT Commons', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(16px, 4vw,24px);
    line-height: 110%;
    max-width: 503px;
  }

  @media screen and (max-width: 1000px){
    position: relative;
    right: 0;
    top: 10%;
    margin-bottom: 48px;

    &-title{
      margin: 20px 0;
    }
  }
}

.active{
  transform: translateY(0);
  opacity: 1;
  transition: all 0.6s ease;
  transform-style: preserve-3d;
  will-change: transform;

}